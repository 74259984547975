import { TProduct } from '@/types'
import { $fetch } from '@/utils'
import { Content } from '@prisma/client'

import { TGetProductBySlug } from './product.types'

export const fetchProducts = async (
  queries?: Record<string, string>
): Promise<TProduct[]> => {
  const queryParams = new URLSearchParams()
  if (queries) {
    Object.entries(queries).forEach(([key, value]) => {
      queryParams.append(key, value)
    })
  }
  const queryString = queryParams.toString()
  const url = queryString ? `/product?${queryString}` : '/product'
  const response = await $fetch<TProduct[]>(url)
  return response.data
}

export const fetchProductBySlug = async ({
  slug,
}: TGetProductBySlug): Promise<
  | TProduct & {
      content: Content
    }
> => {
  const response = await $fetch<
    TProduct & {
      content: Content
    }
  >(`/product/${slug}`)
  return response.data
}
