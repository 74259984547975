import { $fetch } from '@/utils'
import { signIn } from 'next-auth/react'

import { TUserSignUp } from './auth.types'

export const signUp = async (credentials: TUserSignUp): Promise<any> => {
  const response = await $fetch('/sign-up', {
    method: 'POST',
    body: JSON.stringify(credentials),
  })

  return response.data
}

export const login = async ({
  email,
  password,
}: {
  email: string
  password: string
}) => await signIn('credentials', { email, password, callbackUrl: '/' })
