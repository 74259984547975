import { cn } from '@/utils';
export const PageHeader = ({
  title,
  description,
  descriptionClassName
}: {
  title: string;
  description?: string;
  descriptionClassName?: string;
}) => {
  return <div className="flex flex-col gap-7" data-sentry-component="PageHeader" data-sentry-source-file="page-header.tsx">
      <h1 className="text-4xl font-extrabold lg:text-5xl">{title}</h1>
      <p className={cn('max-w-3xl text-lg', descriptionClassName)}>
        {description}
      </p>
    </div>;
};