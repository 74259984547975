/* eslint-disable react-hooks/rules-of-hooks */
import { useId } from 'react';
import Image from 'next/image';
import { Section } from '@/components';
import { cn, getAssetPath, getFromCdn } from '@/utils';
import { CheckCheckIcon } from 'lucide-react';
import { TContentBlock } from './content-block.types';
export const ContentBlock = ({
  content,
  keywords,
  image,
  title,
  description,
  label,
  reverse
}: TContentBlock) => {
  return <Section className="w-full" title={title} label={label} description={description} as="h2" data-sentry-element="Section" data-sentry-component="ContentBlock" data-sentry-source-file="content-block.tsx">
      <div className="grid w-full grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-12">
        <div className="flex flex-col justify-start space-y-4">
          {keywords && <ul className="grid gap-3 text-sm md:grid-cols-2">
              {keywords.map(keyword => <li key={useId()} className="flex items-center gap-2">
                  <CheckCheckIcon className="h-5 w-5 shrink-0 text-primary" />
                  <span>{keyword}</span>
                </li>)}
            </ul>}
          {content && <div className="space-y-4">
              <div className="prose prose-gray mx-auto max-w-none dark:prose-invert" dangerouslySetInnerHTML={{
            __html: content
          }}></div>
            </div>}
        </div>
        <div className={cn('order-first aspect-video overflow-hidden lg:order-last lg:aspect-[3/2]', {
        'lg:order-first': reverse
      })}>
          <Image alt={title} src={image ? getFromCdn(image) : getAssetPath('placeholder.jpg')} width={800} height={600} quality={50} style={{
          borderRadius: '1.5rem',
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }} data-sentry-element="Image" data-sentry-source-file="content-block.tsx" />
        </div>
      </div>
    </Section>;
};