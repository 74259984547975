import { Button, Popover, PopoverContent, PopoverTrigger } from '@/components';
import { InfoIcon } from 'lucide-react';
export const LinkFieldPopover = () => {
  return <Popover data-sentry-element="Popover" data-sentry-component="LinkFieldPopover" data-sentry-source-file="product-info-popover.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="product-info-popover.tsx">
        <Button variant="outline" size="icon" type="button" className="shrink-0 animate-pulse" aria-label="Link Field Descriptions" data-sentry-element="Button" data-sentry-source-file="product-info-popover.tsx">
          <InfoIcon size={16} data-sentry-element="InfoIcon" data-sentry-source-file="product-info-popover.tsx" />
        </Button>
      </PopoverTrigger>
      <PopoverContent side="bottom" align="end" data-sentry-element="PopoverContent" data-sentry-source-file="product-info-popover.tsx">
        <ul className="w-full list-disc space-y-1 px-4 text-xs font-semibold">
          <li>
            Do not put multiple orders for the same link before completion.
          </li>
          <li>Kindly make sure your account is public, Not private.</li>
          <li>Check the link format carefully before placing the order.</li>

          <li>We can not cancel your order once it has been submitted.</li>
        </ul>
      </PopoverContent>
    </Popover>;
};
export const QualityFieldPopover = () => {
  return <Popover data-sentry-element="Popover" data-sentry-component="QualityFieldPopover" data-sentry-source-file="product-info-popover.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="product-info-popover.tsx">
        <Button variant="outline" size="icon" type="button" className="shrink-0 animate-pulse" aria-label="Quality Field Info Button" data-sentry-element="Button" data-sentry-source-file="product-info-popover.tsx">
          <InfoIcon size={16} data-sentry-element="InfoIcon" data-sentry-source-file="product-info-popover.tsx" />
        </Button>
      </PopoverTrigger>
      <PopoverContent side="bottom" align="end" data-sentry-element="PopoverContent" data-sentry-source-file="product-info-popover.tsx">
        <ul className="w-full list-disc space-y-1 px-4 text-xs font-semibold">
          <li>
            Do not put multiple orders for the same link before completion.
          </li>
          <li>Kindly make sure your account is public, Not private.</li>
          <li>Check the link format carefully before placing the order.</li>

          <li>We can not cancel your order once it has been submitted.</li>
        </ul>
      </PopoverContent>
    </Popover>;
};