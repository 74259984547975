import { TOrderParams } from '@/types'
import { $fetch } from '@/utils'
import { Order, OrderItem, User } from '@prisma/client'

export const createOrder = async (credentials: TOrderParams): Promise<any> => {
  const response = await $fetch('/order/create', {
    method: 'POST',
    body: JSON.stringify(credentials),
  })

  return response.data
}

export const getOrder = async (
  id: string
): Promise<
  Order & {
    items: Array<
      OrderItem & {
        product: {
          name: string
          platform: string
        }
      }
    >
  }
> => {
  const response = await $fetch<
    Order & {
      user: User
      items: Array<
        OrderItem & {
          product: {
            name: string
            platform: string
          }
        }
      >
    }
  >(`/order/${id}`)

  return response.data
}
