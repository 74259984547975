import { icons, LucideProps } from 'lucide-react';
interface IconProps extends LucideProps {
  name: keyof typeof icons;
}
const Icon = ({
  name,
  ...props
}: IconProps) => {
  const LucideIcon = icons[name];
  return <LucideIcon {...props} data-sentry-element="LucideIcon" data-sentry-component="Icon" data-sentry-source-file="icon.tsx" />;
};
export default Icon;