'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Button, Container, Logo } from '@/components';
import { isProtectedRoute } from '@/utils';
import { CartRenderer } from './components/cart';
import { DesktopNav } from './desktop-nav';
import { THeader } from './header.types';
import { MobileNav } from './mobile-nav';
import { UserMenu } from './user-menu';
export const Header = ({
  session
}: THeader) => {
  const pathname = usePathname();
  if (isProtectedRoute(pathname)) {
    return null;
  }
  return <Container data-sentry-element="Container" data-sentry-component="Header" data-sentry-source-file="header.tsx">
      <div className="z-10 mt-4 flex h-16 items-center rounded-2xl px-2 shadow-sm">
        <Logo data-sentry-element="Logo" data-sentry-source-file="header.tsx" />
        <div className="ml-auto flex items-center space-x-4 sm:space-x-8">
          <DesktopNav data-sentry-element="DesktopNav" data-sentry-source-file="header.tsx" />
          <div className="flex items-center gap-4">
            <CartRenderer data-sentry-element="CartRenderer" data-sentry-source-file="header.tsx" />
            <MobileNav session={session} data-sentry-element="MobileNav" data-sentry-source-file="header.tsx" />

            {!session ? <div className="hidden items-center gap-2 sm:flex">
                <Button size="lg" variant="outline" className="px-4" asChild>
                  <Link href="/login">Login</Link>
                </Button>
                <Button size="lg" className="px-4" asChild>
                  <Link href="/sign-up">Sign Up</Link>
                </Button>
              </div> : <UserMenu session={session} />}
          </div>
        </div>
      </div>
    </Container>;
};