'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { APP_NAV_ITEMS } from '@/constants';
import { cn } from '@/utils';
export function DesktopNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const currentPath = usePathname();
  return <nav className={cn('hidden items-center space-x-4 sm:flex lg:space-x-6', className)} {...props} data-sentry-component="DesktopNav" data-sentry-source-file="desktop-nav.tsx">
      {APP_NAV_ITEMS.map(item => <Link key={item.href} href={item.href} className={cn('text-sm tracking-tight text-muted-foreground transition-colors hover:text-primary', {
      'text-primary ': currentPath === item.href
    })}>
          {item.label}
        </Link>)}
    </nav>;
}