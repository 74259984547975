import { CartItem } from '@/components/app/cart-item';
import { TOAST_REMOVE_ITEM_FROM_CART } from '@/constants';
import { useToast } from '@/hooks';
import { useCartStore } from '@/providers';
import { TCartItem } from '@/types';
export const CartList = ({
  cart
}: {
  cart: TCartItem[];
}) => {
  const {
    toast
  } = useToast();
  const {
    removeFromCart
  } = useCartStore(state => state);
  if (!cart) {
    return null;
  }
  const handleRemove = ($id: string) => {
    const isRemovedFromCart = removeFromCart($id);
    if (!isRemovedFromCart) {
      toast(TOAST_REMOVE_ITEM_FROM_CART);
    } else {
      toast(TOAST_REMOVE_ITEM_FROM_CART);
    }
  };
  return <>
      {cart?.map((item, index) => <CartItem key={index} name={item.name} link={item.link} id={item.$id} quantity={item.quantity} quality={item.quality} price={item.price} onRemove={handleRemove} />)}
    </>;
};