import { $fetch } from '@/utils'
import { Order } from '@prisma/client'

type TPayment = {
  status: string
  token: string
}
export const createPayment = async (order: Order): Promise<TPayment> => {
  const response = await $fetch('/payment/credit-card/get-token', {
    method: 'POST',
    body: JSON.stringify(order),
  })

  return response.data as TPayment
}
