'use client';

import { useForm } from 'react-hook-form';
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/components';
import { APP_NAME, SUPPORT_MESSAGE_TEMPLATE } from '@/constants';
import { useToast } from '@/hooks';
import { SupportFormSchema } from '@/schemas';
import { postMessage } from '@/services';
import { TSupportFormValues } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Metadata } from 'next';
export const metadata: Metadata = {
  title: `Order Status · ${APP_NAME}`,
  robots: {
    index: false,
    follow: false
  }
};
export const SupportForm = () => {
  const {
    toast
  } = useToast();
  const form = useForm<TSupportFormValues>({
    resolver: zodResolver(SupportFormSchema),
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      subject: '',
      message: ''
    },
    mode: 'onSubmit'
  });
  const onSubmit = async (data: TSupportFormValues) => {
    const response = await postMessage({
      message: SUPPORT_MESSAGE_TEMPLATE(data)
    });
    if (response.ok) {
      form.reset();
      toast({
        title: 'Message Sent',
        description: 'Your message has been sent successfully!',
        variant: 'default'
      });
    }
  };
  return <Form {...form} data-sentry-element="Form" data-sentry-component="SupportForm" data-sentry-source-file="support-form.tsx">
      <form className="flex max-w-screen-lg flex-col gap-6 rounded-lg border p-6" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-4">
          <FormField control={form.control} name="firstname" render={({
          field
        }) => <FormItem className="grid w-full items-center gap-1.5">
                <FormLabel>
                  First Name
                  <sup>*</sup>
                </FormLabel>
                <FormControl>
                  <Input type="text" id="firstname" placeholder="First Name" {...field} />
                </FormControl>
                <FormMessage className="text-left" />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="support-form.tsx" />
          <FormField control={form.control} name="lastname" render={({
          field
        }) => <FormItem className="grid w-full items-center gap-1.5">
                <FormLabel>
                  Last Name
                  <sup>*</sup>
                </FormLabel>
                <FormControl>
                  <Input type="text" id="lastname" placeholder="Last Name" {...field} />
                </FormControl>
                <FormMessage className="text-left" />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="support-form.tsx" />
        </div>

        <FormField control={form.control} name="email" render={({
        field
      }) => <FormItem className="grid w-full items-center gap-1.5">
              <FormLabel>
                Email
                <sup>*</sup>
              </FormLabel>
              <FormControl>
                <Input type="text" id="email" placeholder="Email" {...field} />
              </FormControl>
              <FormMessage className="text-left" />
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="support-form.tsx" />

        <FormField control={form.control} name="subject" render={({
        field
      }) => <FormItem className="grid w-full items-center gap-1.5">
              <FormLabel>
                Subject
                <sup>*</sup>
              </FormLabel>
              <FormControl>
                <Input type="text" id="subject" placeholder="Subject" {...field} />
              </FormControl>
              <FormMessage className="text-left" />
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="support-form.tsx" />

        <FormField control={form.control} name="message" render={({
        field
      }) => <FormItem className="grid w-full items-center gap-1.5">
              <FormLabel>
                Message
                <sup>*</sup>
              </FormLabel>
              <FormControl>
                <Input type="text" id="message" placeholder="Message" {...field} />
              </FormControl>
              <FormMessage className="text-left" />
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="support-form.tsx" />

        <Button type="submit" className="w-full" data-sentry-element="Button" data-sentry-source-file="support-form.tsx">
          Send Message
        </Button>
      </form>
    </Form>;
};