'use client';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Button, Container, Logo, Separator } from '@/components';
import { APP_NAME } from '@/constants';
import { TProduct } from '@/types';
import { capitalizeFirstLetter, getAssetPath, isProtectedRoute } from '@/utils';
import { ChevronUpIcon } from 'lucide-react';
type PlatformMap = {
  [key: string]: {
    title: string;
    links: {
      name: string;
      href: string;
    }[];
  };
};
export const Footer = ({
  products
}: {
  products: TProduct[];
}) => {
  const pathname = usePathname();
  if (isProtectedRoute(pathname)) {
    return null;
  }
  const platformMap = products.reduce<PlatformMap>((acc, item) => {
    const platformTitle = capitalizeFirstLetter(item.platform);
    if (!acc[platformTitle]) {
      acc[platformTitle] = {
        title: platformTitle,
        links: []
      };
    }
    acc[platformTitle].links.push({
      name: `Buy ${platformTitle} ${item.name}`,
      href: `/${item.slug}`
    });
    return acc;
  }, {});
  const footerLinks = [{
    title: 'Company',
    links: [{
      name: 'Support',
      href: '/support'
    }, {
      name: 'Order Status',
      href: '/order-status'
    }, {
      name: 'About',
      href: '/about'
    }, {
      name: 'Blog',
      href: '/blog'
    }, {
      name: 'Term of Services',
      href: '/terms'
    }, {
      name: 'Privacy Policy',
      href: '/privacy'
    }]
  }, {
    title: 'Social',
    links: [{
      name: 'Instagram',
      href: 'https://instagram.com/hqbooster'
    }, {
      name: 'Twitter',
      href: 'https://twitter.com/hqbooster'
    }, {
      name: 'Tiktok',
      href: 'https://tiktok.com/@hqbooster'
    }]
  }, ...Object.values(platformMap)];
  return <Container className="mt-48 pb-16" data-sentry-element="Container" data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
      <footer>
        <div className="flex justify-between gap-4 md:items-center">
          <Logo data-sentry-element="Logo" data-sentry-source-file="footer.tsx" />
          <Button size="icon" onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }} data-sentry-element="Button" data-sentry-source-file="footer.tsx">
            <ChevronUpIcon className="size-6 text-background" data-sentry-element="ChevronUpIcon" data-sentry-source-file="footer.tsx" />
          </Button>
        </div>
        <Separator className="my-14" data-sentry-element="Separator" data-sentry-source-file="footer.tsx" />
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {footerLinks.map(item => <div key={item.title}>
              <h3 className="mb-4 font-bold">{item.title}</h3>
              <ul className="space-y-2 text-muted-foreground">
                {item.links.map((link, index) => <li key={index} className="text-sm hover:text-primary">
                    <Link href={link.href}>{link.name}</Link>
                  </li>)}
              </ul>
            </div>)}
        </div>
        <Separator className="my-14" data-sentry-element="Separator" data-sentry-source-file="footer.tsx" />
        <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
          <p className="text-sm text-muted-foreground">
            {`© ${new Date().getFullYear()} ${APP_NAME}. All rights reserved.`}
          </p>
          <div className="flex items-center gap-8">
            <Image alt="Secure Payment" src={getAssetPath('norton-by-symantec.svg')} width={64} height={64} data-sentry-element="Image" data-sentry-source-file="footer.tsx" />
            <Image alt="Secure Payment" src={getAssetPath('ssl-secured.svg')} width={64} height={64} data-sentry-element="Image" data-sentry-source-file="footer.tsx" />
          </div>
        </div>
      </footer>
    </Container>;
};