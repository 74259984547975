/* eslint-disable react-hooks/rules-of-hooks */
import { useId } from 'react';
import { Card, Section } from '@/components';
import { capitalizeFirstLetter } from '@/utils';
import { ContentFeature } from '@prisma/client';
import { SparklesIcon } from 'lucide-react';
export const FeatureBlockV2 = ({
  platform,
  name,
  features
}: {
  features: ContentFeature[];
  platform: string;
  name: string;
}) => {
  if (!features || features.length === 0) return null;
  return <Section title={`${capitalizeFirstLetter(platform)} ${name} Benefits`} label="Benefits" as="h2" data-sentry-element="Section" data-sentry-component="FeatureBlockV2" data-sentry-source-file="feature-block-v2.tsx">
      <div className="grid gap-6 md:grid-cols-2 lg:gap-12">
        {features.map(feature => <Card key={useId()} className="col-span-1 flex gap-4 p-4">
            <SparklesIcon className="mt-2 h-8 w-8 shrink-0" />
            <div className="ms-5 sm:ms-8">
              <h3 className="text-base font-semibold sm:text-lg">
                {feature.title}
              </h3>
              <p className="mt-1 text-muted-foreground">
                {feature.description}
              </p>
            </div>
          </Card>)}
      </div>
    </Section>;
};