import Image from 'next/image';
import { Card, Section } from '@/components';
import { APP_STATS } from '@/constants';
import { cn, getAssetPath } from '@/utils';
export const OurStats = () => {
  return <Section title="Our Stats" label="Stats" description="Delivering unmatched excellence, ensuring satisfaction, and achieving results that exceed all expectations." data-sentry-element="Section" data-sentry-component="OurStats" data-sentry-source-file="our-stats.tsx">
      <Card className={cn('flex h-full flex-col-reverse items-center gap-6 border-0 bg-gray-50 p-6 shadow-none lg:flex-row lg:gap-16 lg:p-8')} data-sentry-element="Card" data-sentry-source-file="our-stats.tsx">
        <div className="grid w-full grid-cols-1 gap-x-10 gap-y-4 md:grid-cols-2 lg:gap-y-16">
          {APP_STATS.map(item => <div key={item.id} className="flex flex-col items-start gap-1">
              <h3 className="text-4xl font-extrabold leading-tight text-primary">
                {item.title}
              </h3>
              <h3 className="text-base font-bold leading-tight text-secondary-foreground">
                {item.subtitle}
              </h3>
              <p className="text-muted-foreground">{item.description}</p>
            </div>)}
        </div>
        <div className="shrink-0">
          <Image src={getAssetPath('our-stats.jpg')} width={500} height={500} alt="Our Stats" className="rounded-3xl" data-sentry-element="Image" data-sentry-source-file="our-stats.tsx" />
        </div>
      </Card>
    </Section>;
};