import { $fetch } from '@/utils'

export const postMessage = async ({
  message,
}: {
  message: string
}): Promise<any> => {
  const response = await $fetch('/telegram', {
    method: 'POST',
    body: JSON.stringify({ message }),
  })

  return response
}
