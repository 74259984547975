/* eslint-disable react-hooks/rules-of-hooks */
import { useId } from 'react';
import { Section } from '@/components';
import { cn } from '@/utils';
import { HelpCircleIcon } from 'lucide-react';
import { TFaqBlock } from './faq-block.types';
export const FaqBlock = ({
  faqs
}: TFaqBlock) => {
  if (!faqs || faqs.length === 0) return null;
  return <Section title="Questions & Answers" label="FAQ" description="Here are some of the most common questions we get from our customers
            about our products and services." as="h2" data-sentry-element="Section" data-sentry-component="FaqBlock" data-sentry-source-file="faq-block.tsx">
      <div className="columns-1 gap-4 sm:columns-2 sm:gap-8 md:columns-3">
        {faqs?.map(({
        question,
        answer
      }) => <div key={useId()} className={cn('mt-6 inline-block rounded-lg bg-secondary p-6')}>
            <div className="flex items-start">
              <HelpCircleIcon className="mr-4 mt-1 h-6 w-6 shrink-0" />
              <div>
                <h4 className="mb-2 text-xl font-semibold">{question}</h4>
                <p className="text-sm">{answer}</p>
              </div>
            </div>
          </div>)}
      </div>
    </Section>;
};