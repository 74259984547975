'use client';

import { createContext, type ReactNode, useContext, useRef } from 'react';
import { createCartStore, TCartStore } from '@/stores';
import { TProduct } from '@/types';
import { useStore } from 'zustand';
export type CartStoreApi = ReturnType<typeof createCartStore>;
export const CartStoreContext = createContext<CartStoreApi | undefined>(undefined);
export interface CartStoreProviderProps {
  children: ReactNode;
  products: TProduct[];
}
export const CartStoreProvider = ({
  children,
  products
}: CartStoreProviderProps) => {
  const storeRef = useRef<CartStoreApi>();
  if (!storeRef.current) {
    storeRef.current = createCartStore({
      $cart: [],
      $products: products,
      $open: false
    });
  }
  return <CartStoreContext.Provider value={storeRef.current} data-sentry-element="unknown" data-sentry-component="CartStoreProvider" data-sentry-source-file="cart-store-provider.tsx">
      {children}
    </CartStoreContext.Provider>;
};
export const useCartStore = <T,>(selector: (store: TCartStore) => T): T => {
  const cartStoreContext = useContext(CartStoreContext);
  if (!cartStoreContext) {
    throw new Error(`useCounterStore must be used within CounterStoreProvider`);
  }
  return useStore(cartStoreContext, selector);
};