'use client';

import { useEffect, useState } from 'react';
import { Button } from '@/components';
import { ShoppingCartIcon } from 'lucide-react';
export const CartButton = ({
  length: lengthProps,
  disabled,
  ...rest
}: {
  length: number;
  disabled: boolean;
  onClick: () => void;
}) => {
  const [length, setLength] = useState(0);
  useEffect(() => {
    setLength(lengthProps);
  }, [lengthProps]);
  return <Button variant="outline" size="icon" className="relative flex h-11 w-11 shrink-0 cursor-pointer rounded-full" disabled={disabled} {...rest} aria-label="My Cart" data-sentry-element="Button" data-sentry-component="CartButton" data-sentry-source-file="cart-button.tsx">
      <ShoppingCartIcon size={20} data-sentry-element="ShoppingCartIcon" data-sentry-source-file="cart-button.tsx" />
      {!disabled && length > 0 && <span className={'absolute -right-1.5 -top-1.5 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs font-bold text-white ring-1 ring-white dark:text-primary'}>
          {length > 9 ? '9+' : length}
        </span>}
    </Button>;
};