import Image from 'next/image';
import { Section } from '@/components';
import { getAssetPath } from '@/utils';
import { TStepBlock } from './step-block.types';
export const StepBlock = ({
  title,
  description,
  items
}: TStepBlock) => {
  return <Section title={title} description={description} as="h2" data-sentry-element="Section" data-sentry-component="StepBlock" data-sentry-source-file="step-block.tsx">
      <div className="relative">
        <div className="absolute inset-x-0 top-2 hidden md:block md:px-20 lg:px-28 xl:px-44">
          <Image alt="Curved Dotted Line" width={875} height={48} className="w-full" src={getAssetPath('curved-dotted-line.svg')} style={{
          maxWidth: '100%',
          height: 'auto'
        }} data-sentry-element="Image" data-sentry-source-file="step-block.tsx" />
        </div>
        <div className="relative grid grid-cols-1 gap-x-12 gap-y-12 text-left md:grid-cols-3 md:text-center">
          {items.map(({
          description,
          title,
          id
        }, index) => <div className="flex flex-row items-start gap-4 md:flex-col md:items-center" key={id}>
              <div className="mx-0 flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl bg-primary md:mx-auto">
                <span className="text-xl font-bold text-secondary">
                  {index + 1}
                </span>
              </div>
              <div>
                <h3 className="text-lg font-bold md:mt-10">{title}</h3>
                <p className="mt-4 text-muted-foreground ">{description}</p>
              </div>
            </div>)}
        </div>
      </div>
    </Section>;
};