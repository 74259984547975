'use client';

import { Avatar, AvatarFallback, AvatarImage, Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components';
import { TOAST_LOGOUT_SUCCESS } from '@/constants';
import { useToast } from '@/hooks';
import { getShortName } from '@/utils';
import { Session } from 'next-auth';
import { signOut } from 'next-auth/react';
export function UserMenu({
  session
}: {
  session: Session | null;
}) {
  const {
    toast
  } = useToast();
  const handleLogout = async () => {
    await signOut();
    toast(TOAST_LOGOUT_SUCCESS);
  };
  if (!session) return null;
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="UserMenu" data-sentry-source-file="user-menu.tsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="user-menu.tsx">
        <Button variant="ghost" className="relative hidden h-11 w-11 rounded-full sm:flex" data-sentry-element="Button" data-sentry-source-file="user-menu.tsx">
          <Avatar className="h-11 w-11" data-sentry-element="Avatar" data-sentry-source-file="user-menu.tsx">
            {session?.user?.image && <AvatarImage src={session?.user?.image} alt={`${session?.user?.name} Avatar` || 'User Avatar'} />}

            <AvatarFallback className="text-md" data-sentry-element="AvatarFallback" data-sentry-source-file="user-menu.tsx">
              {getShortName(session?.user?.name ?? 'HQ')}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount data-sentry-element="DropdownMenuContent" data-sentry-source-file="user-menu.tsx">
        <DropdownMenuLabel className="font-normal" data-sentry-element="DropdownMenuLabel" data-sentry-source-file="user-menu.tsx">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {session?.user?.name}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {session?.user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="user-menu.tsx" />
        <DropdownMenuItem onClick={handleLogout} data-sentry-element="DropdownMenuItem" data-sentry-source-file="user-menu.tsx">Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>;
}