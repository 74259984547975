import Image from 'next/image';
import Link from 'next/link';
import { getAssetPath } from '@/utils';
export const Logo = ({
  size = 40
}) => <Link href="/" data-sentry-element="Link" data-sentry-component="Logo" data-sentry-source-file="logo.tsx">
    <Image src={getAssetPath('hqbooster.svg')} alt="HQBooster Logo" width={size} height={size} priority
  // style={{
  //   maxWidth: "100%",
  //   height: "auto"
  // }}
  className="h-12 w-12 max-w-full rounded-lg" data-sentry-element="Image" data-sentry-source-file="logo.tsx" />
  </Link>;