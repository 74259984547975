import Image from 'next/image';
import Link from 'next/link';
import { Button, Card, Section } from '@/components';
import { APP_COMMUNITY_URLS } from '@/constants';
import { getAssetPath } from '@/utils';
export const CommunityBlock = () => {
  return <Section data-sentry-element="Section" data-sentry-component="CommunityBlock" data-sentry-source-file="community-block.tsx">
      <Card className="flex flex-col-reverse justify-between gap-4 border-0 bg-gray-50 p-6 shadow-none sm:gap-16 md:p-8 lg:flex-row" data-sentry-element="Card" data-sentry-source-file="community-block.tsx">
        <div>
          <h2 className="mx-auto text-3xl font-extrabold md:text-3xl lg:text-left">
            Join the Community
          </h2>
          <p className="mx-0 mb-4 mt-4 max-w-2xl text-left text-base text-muted-foreground sm:mb-12 md:text-lg">
            Be part of our growing community and stay updated with the latest
            news and updates. Engage in discussions, share your ideas, and
            connect with like-minded members to make a difference together.
          </p>
          <div className="flex items-center justify-between gap-4 lg:justify-start">
            {APP_COMMUNITY_URLS.map(({
            name,
            url
          }) => <Button key={name} size="lg" className="sm:px-auto flex h-12 w-full items-center gap-4 p-0 sm:h-14" asChild>
                <Link href={url} target="_blank" referrerPolicy="no-referrer">
                  <span className="h-6 w-6 sm:h-8 sm:w-8">
                    <Image src={getAssetPath(`cta-${name.toLowerCase()}.svg`)} alt={`${name} Logo`} width={32} height={32} className="h-full w-full" />
                  </span>

                  <span className="text-md hidden font-bold sm:block">
                    {name}
                  </span>
                </Link>
              </Button>)}
          </div>
        </div>
      </Card>
    </Section>;
};