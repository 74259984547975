import { PRODUCT_QUALITY_KEYS } from '@/constants'
import {
  preprocess,
  enum as zEnum,
  nativeEnum as zNativeEnum,
  number as zNumber,
  object as zObject,
  ZodEffects,
  ZodEnum,
  ZodNativeEnum,
  ZodNumber,
  ZodObject,
  ZodString,
  string as zString,
} from 'zod'

export const generateProductFormSchema = ({
  regex,
  label,
  minQuantity,
}: {
  regex: string
  label: string
  minQuantity: number
}): ZodObject<{
  link: ZodString
  quantity: ZodEffects<ZodNumber, number | string, unknown>
  quality: ZodNativeEnum<typeof PRODUCT_QUALITY_KEYS>
  id: ZodString
  platform: ZodEnum<['tiktok', 'instagram']>
}> => {
  return zObject({
    link: regex
      ? zString().regex(new RegExp(regex), {
          message: `Please enter valid ${label}`,
        })
      : zString().min(1, { message: `Please enter ${label}` }),
    quantity: preprocess(
      (val) => parseInt(val as string, 10),
      zNumber().min(minQuantity, {
        message: `Minimum quantity is ${minQuantity}`,
      })
    ),
    id: zString().min(1, { message: 'Please select a product' }),
    quality: zNativeEnum(PRODUCT_QUALITY_KEYS),
    platform: zEnum(['tiktok', 'instagram']) || zString(),
  })
}

export const CartItemSchema = zObject({
  platform: zEnum(['tiktok', 'instagram']) || zString(),
  id: zString(),
  quantity: zNumber(),
  quality: zNativeEnum(PRODUCT_QUALITY_KEYS),
  link: zString(),
})

export const CheckoutFormSchema = zObject({
  name: zString().min(2, { message: 'Name is required' }),
  email: zString().email({ message: 'Invalid email' }),
  phone: zString().min(8, { message: 'Phone is required' }),
  address: zString().min(6, { message: 'Address is required' }),
})

export const SignUpAPISchema = zObject({
  name: zString(),
  email: zString().email(),
  password: zString().min(6),
  passwordConfirm: zString().min(6),
})

export const CreateOrderAPISchema = zObject({
  name: zString(),
  email: zString().email(),
  phone: zString().min(8),
  address: zString().min(6),
  cart: CartItemSchema.array(),
})

export const OrderStatusSchema = zObject({
  orderId: zString().min(1),
})

export const SupportFormSchema = zObject({
  firstname: zString().min(2, { message: 'First name is required' }),
  lastname: zString().min(2, { message: 'Last name is required' }),
  email: zString().email({ message: 'Invalid email' }),
  subject: zString().min(6, { message: 'Subject is required' }),
  message: zString().min(6, { message: 'Message is required' }),
})
