'use client';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Link from 'next/link';
import { Badge, Button, Card, CardContent, CardFooter, CardHeader, CardTitle, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Skeleton } from '@/components';
import { LinkFieldPopover } from '@/components';
import { PRODUCT_PLATFORMS, PRODUCT_QUALITIES, PRODUCT_QUALITY_KEYS, TOAST_ADD_ITEM_TO_CART, TOAST_ADD_ITEM_TO_CART_ERROR } from '@/constants';
import { useToast } from '@/hooks';
import { useCartStore } from '@/providers';
import { generateProductFormSchema } from '@/schemas';
import { fetchProducts } from '@/services';
import { TOrderFormValues, TProduct } from '@/types';
import { formatCurrency, formatNumber, offerGenerator } from '@/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreditCardIcon, ShoppingCartIcon } from 'lucide-react';
export const QuickOrderForm = () => {
  const form = useForm<TOrderFormValues>({
    resolver: zodResolver(generateProductFormSchema({
      regex: '',
      label: '',
      minQuantity: 50
    })),
    defaultValues: {
      id: '',
      quantity: '0',
      link: '',
      quality: PRODUCT_QUALITY_KEYS.STANDARD,
      platform: 'instagram'
    }
  });
  const {
    watch,
    handleSubmit,
    control,
    setValue
  } = form;
  const {
    toast
  } = useToast();
  const {
    addToCart,
    cartSize
  } = useCartStore(state => state);
  const [products, setProducts] = useState<TProduct[] | undefined>(undefined);
  const [activeProduct, setActiveProduct] = useState<TProduct | undefined>(undefined);
  const platformWatcher = watch('platform');
  const qualityWatcher = watch('quality');
  const productIdWatcher = watch('id');
  useEffect(() => {
    fetchProducts({
      platform: platformWatcher
    }).then(data => {
      setProducts(data);
      setValue('id', data[0].id);
      setValue('quantity', data[0].quantity.toString());
      setActiveProduct(data[0]);
    });
    return () => {
      setProducts(undefined);
      setActiveProduct(undefined);
    };
  }, [platformWatcher, setValue]);
  useEffect(() => {
    const activeProduct = products?.find(product => product.id === productIdWatcher) as TProduct | undefined;
    setActiveProduct(activeProduct);
    return () => {
      setActiveProduct(undefined);
    };
  }, [productIdWatcher, products]);
  const isLoaded = !!activeProduct;
  const handleAddToCart = ({
    link,
    quality,
    quantity,
    id,
    platform
  }: TOrderFormValues) => {
    const values = {
      id,
      quantity: Number(quantity),
      quality: quality || PRODUCT_QUALITY_KEYS.STANDARD,
      link,
      platform
    };
    const isAddedToCart = addToCart(values);
    if (isAddedToCart) {
      toast(TOAST_ADD_ITEM_TO_CART);
    } else {
      toast({
        ...TOAST_ADD_ITEM_TO_CART_ERROR,
        variant: 'destructive'
      });
    }
  };
  const offers = offerGenerator({
    price: activeProduct?.price?.[qualityWatcher] || 0,
    quantity: activeProduct?.quantity || 0
  });
  return <Card className="col-span-1 h-fit shrink-0 border-0 shadow-lg" data-sentry-element="Card" data-sentry-component="QuickOrderForm" data-sentry-source-file="quick-order.tsx">
      <CardHeader className="p-3 sm:p-4 md:p-6" data-sentry-element="CardHeader" data-sentry-source-file="quick-order.tsx">
        <CardTitle className="flex items-center justify-between text-lg lg:text-2xl" data-sentry-element="CardTitle" data-sentry-source-file="quick-order.tsx">
          Quick Purchase
          <small className="hidden text-sm font-normal text-muted-foreground lg:block">
            Get your order in a few clicks
          </small>
        </CardTitle>
      </CardHeader>
      <CardContent className="px-3 sm:px-4 md:px-6" data-sentry-element="CardContent" data-sentry-source-file="quick-order.tsx">
        <Form {...form} data-sentry-element="Form" data-sentry-source-file="quick-order.tsx">
          <form className="space-y-7">
            <div className="space-y-2">
              <div className="grid grid-cols-2 gap-4">
                <FormField control={control} name="platform" render={({
                field
              }) => <FormItem>
                      <FormLabel>Platforms</FormLabel>
                      {isLoaded ? <Select {...field} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a platform" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {PRODUCT_PLATFORMS?.map(platform => <SelectItem key={platform.uuid} value={platform.uuid}>
                                {platform.name}
                              </SelectItem>)}
                          </SelectContent>
                        </Select> : <Skeleton className="h-10 w-full" />}
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="quick-order.tsx" />

                <FormField control={control} name="id" render={({
                field
              }) => <FormItem>
                      <FormLabel>Products</FormLabel>
                      {isLoaded ? <Select {...field} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a product" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {products?.map(product => <SelectItem key={product.id} value={product.id}>
                                {product.name}
                              </SelectItem>)}
                          </SelectContent>
                        </Select> : <Skeleton className="h-10 w-full" />}
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="quick-order.tsx" />
              </div>
              <div className="grid gap-2 lg:grid-cols-2 lg:gap-4">
                <FormField control={control} name="quantity" render={({
                field
              }) => <FormItem>
                      <FormLabel>Quantity</FormLabel>
                      {isLoaded ? <Select {...field} value={field.value.toString()} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select an offer" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {offers?.map(offer => <SelectItem key={offer?.quantity.toString()} value={offer?.quantity.toString()}>
                                <div className="space-x-3">
                                  <span className="font-semibold">
                                    {`${formatNumber(offer?.quantity)} ${activeProduct?.name}`}
                                  </span>

                                  <Badge className="h-4 px-2 py-0">
                                    {formatCurrency(offer?.price)}
                                  </Badge>
                                </div>
                              </SelectItem>)}
                          </SelectContent>
                        </Select> : <Skeleton className="h-10 w-full" />}
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="quick-order.tsx" />

                <FormField control={form.control} name="quality" render={({
                field
              }) => <FormItem className="w-full">
                      <FormLabel>Quality</FormLabel>
                      {isLoaded ? <Select {...field} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select Service Quality" defaultValue={PRODUCT_QUALITY_KEYS.STANDARD} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {PRODUCT_QUALITIES(activeProduct.name)?.map(product => <SelectItem key={product.value} value={product.value}>
                                  <div className="flex items-center gap-2">
                                    <product.Icon className={product.iconClassName} size={16} />
                                    <span>{product.label}</span>
                                  </div>
                                </SelectItem>)}
                          </SelectContent>
                        </Select> : <Skeleton className="h-10 w-full" />}
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="quick-order.tsx" />
              </div>
              <div className="flex w-full items-end gap-2 ">
                <FormField control={control} name="link" render={({
                field
              }) => <FormItem className="w-full">
                      <FormLabel>
                        {isLoaded ? <>
                            {activeProduct?.input?.label}
                            <sup>*</sup>
                          </> : 'Loading...'}
                      </FormLabel>
                      <FormControl>
                        <div className="flex gap-2">
                          {isLoaded ? <Input placeholder={activeProduct?.input.placeholder} {...field} /> : <Skeleton className="h-10 w-full" />}
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>} data-sentry-element="FormField" data-sentry-source-file="quick-order.tsx" />
                <LinkFieldPopover data-sentry-element="LinkFieldPopover" data-sentry-source-file="quick-order.tsx" />
              </div>
            </div>

            <CardFooter className="flex justify-between space-x-4 p-0" data-sentry-element="CardFooter" data-sentry-source-file="quick-order.tsx">
              {isLoaded ? <>
                  <Button className="w-full gap-2 px-0 font-bold" variant="outline" type="submit" size="lg" onClick={handleSubmit(handleAddToCart)}>
                    ADD TO CART{' '}
                    <ShoppingCartIcon className="fill-primary" size={16} />
                  </Button>
                  {cartSize() > 0 && <Button className="w-full gap-2 px-0 font-bold" size="lg" asChild>
                      <Link href="/checkout">
                        CHECKOUT <CreditCardIcon size={16} />
                      </Link>
                    </Button>}
                </> : <>
                  <Skeleton className="h-11 w-full" />
                  <Skeleton className="h-11 w-full" />
                </>}
            </CardFooter>
          </form>
        </Form>
      </CardContent>
    </Card>;
};