import Image from 'next/image';
import { Card, Section } from '@/components';
import { APP_QUESTIONS_ANSWERS } from '@/constants';
import { cn, getAssetPath } from '@/utils';
import { HelpCircle } from 'lucide-react';
export const QuestionsAnswers = () => {
  return <Section title="Questions & Answers" label="QA" description="Get answers to your questions and learn more about how HQBooster works. Our team is here to help!" data-sentry-element="Section" data-sentry-component="QuestionsAnswers" data-sentry-source-file="questions-answers.tsx">
      <Card className={cn('flex h-full flex-col items-center gap-6 border-0 bg-gray-50 p-6 shadow-none lg:flex-row lg:gap-16 lg:p-8')} data-sentry-element="Card" data-sentry-source-file="questions-answers.tsx">
        <div className="shrink-0">
          <Image src={getAssetPath('questions-and-answers.jpg')} alt="Questions & Answers" width={500} height={500} className="rounded-3xl" data-sentry-element="Image" data-sentry-source-file="questions-answers.tsx" />
        </div>

        <div className="flex flex-col gap-8">
          {APP_QUESTIONS_ANSWERS.map(({
          title,
          description,
          id
        }) => <div className="flex gap-2 lg:gap-6" key={id}>
              <div>
                <HelpCircle />
              </div>
              <div className="flex flex-col items-start gap-1">
                <h3 className="text-dark-grey-900 text-base font-bold leading-tight">
                  {title}
                </h3>

                <p className="text-muted-foreground">{description}</p>
              </div>
            </div>)}
        </div>
      </Card>
    </Section>;
};