'use client';

import { useState } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Avatar, AvatarFallback, AvatarImage, Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from '@/components';
import { APP_NAME, APP_NAV_ITEMS } from '@/constants';
import { cn } from '@/utils';
import { LogOut, MenuIcon, Settings } from 'lucide-react';
import { Session } from 'next-auth';
export const MobileNav = ({
  session
}: {
  session: Session | null;
}) => {
  const currentPath = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  return <Drawer open={isOpen} data-sentry-element="Drawer" data-sentry-component="MobileNav" data-sentry-source-file="mobile-nav.tsx">
      <DrawerTrigger asChild data-sentry-element="DrawerTrigger" data-sentry-source-file="mobile-nav.tsx">
        <Button variant="outline" size="icon" className="flex h-12 w-12 shrink-0 rounded-full sm:hidden" onClick={() => setIsOpen(!isOpen)} data-sentry-element="Button" data-sentry-source-file="mobile-nav.tsx">
          <MenuIcon size={20} data-sentry-element="MenuIcon" data-sentry-source-file="mobile-nav.tsx" />
        </Button>
      </DrawerTrigger>
      <DrawerContent data-sentry-element="DrawerContent" data-sentry-source-file="mobile-nav.tsx">
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader data-sentry-element="DrawerHeader" data-sentry-source-file="mobile-nav.tsx">
            <DrawerTitle data-sentry-element="DrawerTitle" data-sentry-source-file="mobile-nav.tsx">{APP_NAME}</DrawerTitle>
          </DrawerHeader>
          <div className="px-4 py-8">
            <div className="flex flex-col items-center gap-2">
              {APP_NAV_ITEMS.map(item => <Link key={item.href} href={item.href} onClick={() => setIsOpen(false)} className={cn('text-md font-medium text-gray-500 transition-colors hover:text-primary', {
              'text-primary': currentPath === item.href
            })}>
                  {item.label}
                </Link>)}
            </div>
          </div>
          <DrawerFooter data-sentry-element="DrawerFooter" data-sentry-source-file="mobile-nav.tsx">
            {session ? <div className="flex items-center justify-between">
                <div className="flex items-center justify-between gap-2">
                  <Avatar className="h-12 w-12">
                    <AvatarImage src="/avatars/01.png" alt={`${session?.user?.name} Avatar` || 'User Avatar'} />
                    <AvatarFallback className="text-md">
                      {session?.user?.name?.split(' ').map(name => name[0].toUpperCase())}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">
                      {session?.user?.name}
                    </p>
                    <p className="text-xs leading-none text-muted-foreground">
                      {session?.user?.email}
                    </p>
                  </div>
                </div>

                <div className="flex gap-2">
                  <Button size="icon" variant="outline">
                    <Settings size={20} />
                  </Button>
                  <Button size="icon" variant="outline">
                    <LogOut size={20} />
                  </Button>
                </div>
              </div> : <div className="flex items-center gap-2">
                <Button size="lg" variant="outline" className="w-full" asChild>
                  <Link href="/login"> Log In </Link>
                </Button>
                <Button size="lg" className="w-full" asChild>
                  <Link href="/sign-up"> Sign Up </Link>
                </Button>
              </div>}
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>;
};