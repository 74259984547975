import * as React from 'react';
import { ButtonProps, buttonVariants } from '@/components/ui/button';
import { cn } from '@/utils';
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react';
const Pagination = ({
  className,
  ...props
}: React.ComponentProps<'nav'>) => <nav role="navigation" aria-label="pagination" className={cn('mx-auto flex w-full justify-center', className)} {...props} data-sentry-component="Pagination" data-sentry-source-file="pagination.tsx" />;
const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(({
  className,
  ...props
}, ref) => <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />);
PaginationContent.displayName = 'PaginationContent';
const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({
  className,
  ...props
}, ref) => <li ref={ref} className={cn('', className)} {...props} />);
PaginationItem.displayName = 'PaginationItem';
type PaginationLinkProps = {
  isActive?: boolean;
} & Pick<ButtonProps, 'size'> & React.ComponentProps<'a'>;
const PaginationLink = ({
  className,
  isActive,
  size = 'icon',
  ...props
}: PaginationLinkProps) => <PaginationItem data-sentry-element="PaginationItem" data-sentry-component="PaginationLink" data-sentry-source-file="pagination.tsx">
    <a aria-current={isActive ? 'page' : undefined} className={cn(buttonVariants({
    variant: isActive ? 'outline' : 'ghost',
    size
  }), className)} {...props} />
  </PaginationItem>;
PaginationLink.displayName = 'PaginationLink';
const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => <PaginationLink aria-label="Go to previous page" size="default" className={cn('gap-1 pl-2.5', className)} {...props} data-sentry-element="PaginationLink" data-sentry-component="PaginationPrevious" data-sentry-source-file="pagination.tsx">
    <ChevronLeft className="h-4 w-4" data-sentry-element="ChevronLeft" data-sentry-source-file="pagination.tsx" />
    <span>Previous</span>
  </PaginationLink>;
PaginationPrevious.displayName = 'PaginationPrevious';
const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => <PaginationLink aria-label="Go to next page" size="default" className={cn('gap-1 pr-2.5', className)} {...props} data-sentry-element="PaginationLink" data-sentry-component="PaginationNext" data-sentry-source-file="pagination.tsx">
    <span>Next</span>
    <ChevronRight className="h-4 w-4" data-sentry-element="ChevronRight" data-sentry-source-file="pagination.tsx" />
  </PaginationLink>;
const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => <span aria-hidden className={cn('flex h-9 w-9 items-center justify-center', className)} {...props} data-sentry-component="PaginationEllipsis" data-sentry-source-file="pagination.tsx">
    <MoreHorizontal className="h-4 w-4" data-sentry-element="MoreHorizontal" data-sentry-source-file="pagination.tsx" />
    <span className="sr-only">More pages</span>
  </span>;
export { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious };