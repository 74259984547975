import Image from 'next/image';
import { getAssetPath } from '@/utils';
import { TEmo } from './emo.types';
export const Emo = ({
  name,
  size = 32,
  quality = '64x',
  ...props
}: TEmo) => {
  return <Image {...props} src={getAssetPath(`emo/${quality}/${name}.gif`)} width={size} height={size} alt={`${name} Emoji}`} priority style={{
    maxWidth: '100%',
    height: 'auto'
  }} data-sentry-element="Image" data-sentry-component="Emo" data-sentry-source-file="emo.tsx" />;
};