import { Section } from '@/components';
import { APP_FEATURES, APP_NAME } from '@/constants';
export const FeatureBlock = () => {
  return <Section title={`Why Choose ${APP_NAME}?`} description="We offer a wide range of features that make us the best choice for your needs." label="Our Features" as="h2" data-sentry-element="Section" data-sentry-component="FeatureBlock" data-sentry-source-file="feature-block.tsx">
      <div className="flex h-fit flex-col items-stretch justify-center gap-4 lg:flex-row">
        {APP_FEATURES.map(({
        Icon,
        description,
        title,
        id
      }) => <div key={id} className="col-span-1 flex h-auto w-full flex-row items-start gap-4 rounded-2xl bg-gray-50 p-6 lg:flex-col lg:items-center">
            <div className="flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl bg-primary">
              <Icon className="text-white" size={28} />
            </div>
            <div className="flex flex-col gap-2 text-left lg:text-center">
              <h3 className="text-lg font-bold">{title}</h3>
              <p className="text-muted-foreground">{description}</p>
            </div>
          </div>)}
      </div>
    </Section>;
};