export { FeatureBlock, FeatureBlockV2 } from './feature-block'
export { PlatformBlock } from './platform-block'
export { StepBlock } from './step-block'
export { ReviewBlock } from './review-block'
export { FaqBlock } from './faq-block'
export { CommunityBlock } from './community-block'
export { SupportBlock } from './support-block'
export {
  ContentBlock,
  WhyChooseUs,
  OurStats,
  QuestionsAnswers,
} from './content-block'
