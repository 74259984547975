import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../card';
import { Skeleton } from '../skeleton';
export const QuickOrderLoader = () => {
  return <Card className="col-span-5 h-fit w-full" data-sentry-element="Card" data-sentry-component="QuickOrderLoader" data-sentry-source-file="quick-order.loader.tsx">
      <CardHeader className="p-3 sm:p-4 md:p-6" data-sentry-element="CardHeader" data-sentry-source-file="quick-order.loader.tsx">
        <CardTitle className="font-bold" data-sentry-element="CardTitle" data-sentry-source-file="quick-order.loader.tsx">Quick Purchase</CardTitle>
        <CardDescription className="text-gray-600" data-sentry-element="CardDescription" data-sentry-source-file="quick-order.loader.tsx">
          Please wait while we load the form...
        </CardDescription>
      </CardHeader>

      <CardContent className="px-3 sm:px-4 md:px-6" data-sentry-element="CardContent" data-sentry-source-file="quick-order.loader.tsx">
        <div className="space-y-7">
          <div className="space-y-2">
            <div className="grid grid-cols-2 gap-4">
              <Skeleton className="mt-8 h-10 w-full" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
              <Skeleton className="mt-8 h-10 w-full" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
            </div>
            <div className="w-full">
              <Skeleton className="mt-10 h-10 w-full" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
            </div>
            <div className="flex items-end gap-2">
              <Skeleton className="mt-8 h-10 w-full" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
              <Skeleton className="mt-8 h-10 w-10 shrink-0" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
            </div>
            <div className="flex items-end gap-2">
              <Skeleton className="mt-8 h-10 w-full" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
              <Skeleton className="mt-8 h-10 w-10 shrink-0" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
            </div>
          </div>

          <CardFooter className="flex justify-between space-x-4 p-0" data-sentry-element="CardFooter" data-sentry-source-file="quick-order.loader.tsx">
            <Skeleton className="h-11 w-full" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
            <Skeleton className="h-11 w-full" data-sentry-element="Skeleton" data-sentry-source-file="quick-order.loader.tsx" />
          </CardFooter>
        </div>
      </CardContent>
    </Card>;
};