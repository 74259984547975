/* eslint-disable react-hooks/rules-of-hooks */
import { useId } from 'react';
import { Section } from '@/components';
import { Quote } from 'lucide-react';
import { TReviewBlock } from './review-block.types';
export const ReviewBlock = ({
  reviews
}: TReviewBlock) => {
  if (!reviews || reviews.length === 0) return null;
  return <Section title="Customer Reviews" description="Here's what some of our customers have to say about our products and services." label="Reviews" as="h2" data-sentry-element="Section" data-sentry-component="ReviewBlock" data-sentry-source-file="review-block.tsx">
      <div className="columns-1 gap-4 sm:columns-2 sm:gap-8 md:columns-3 lg:columns-3 [&>blockquote:not(:first-child)]:mt-6">
        {reviews?.map(({
        name,
        review
      }) => <blockquote key={useId()} className="h-fit w-full overflow-hidden rounded-lg transition-all hover:-translate-y-1 hover:shadow-xl">
            <div className="flex h-full flex-col justify-center bg-secondary p-6">
              <Quote className="mb-4 h-8 w-8 text-primary opacity-50" />
              <p className="mb-4 text-lg font-semibold text-primary">
                {review}
              </p>
              <span>
                -
                <cite className="text-right text-sm text-primary opacity-75">
                  {name}
                </cite>
              </span>
            </div>
          </blockquote>)}
      </div>
    </Section>;
};