import Link from 'next/link';
import { Button, Card, Section } from '@/components';
import { APP_EMAIL, APP_PHONE } from '@/constants';
import { HeartHandshake } from 'lucide-react';
export const SupportBlock = () => {
  return <Section data-sentry-element="Section" data-sentry-component="SupportBlock" data-sentry-source-file="support-block.tsx">
      <Card className="border-0 bg-gray-50 p-6 shadow-none sm:p-6 lg:p-8" data-sentry-element="Card" data-sentry-source-file="support-block.tsx">
        <div className="md:flex md:justify-between">
          <div className=" mb-4 md:mb-0">
            <h2 className="mb-4 text-3xl font-extrabold leading-7 text-primary">
              Get In Touch
            </h2>
            <p className="mb-6 max-w-md text-muted-foreground">
              Have questions or just want to say hello? We&apos;re here to help
              and make communication easy for you.
            </p>
            <div className="flex justify-start">
              <Button asChild size="lg" data-sentry-element="Button" data-sentry-source-file="support-block.tsx">
                <Link href="/support" className="flex gap-2" data-sentry-element="Link" data-sentry-source-file="support-block.tsx">
                  Contact Us
                  <HeartHandshake size={16} data-sentry-element="HeartHandshake" data-sentry-source-file="support-block.tsx" />
                </Link>
              </Button>
            </div>
          </div>
          <div className="md:border-gray-150 border-l-2 border-transparent md:px-10">
            <div className="mb-8 flex flex-col">
              <span className="pb-3 text-sm font-medium text-muted-foreground md:text-start">
                Email Address
              </span>
              <a href={`mailto:${APP_EMAIL}`} className=" text-xl font-semibold text-primary md:text-start">
                {APP_EMAIL}
              </a>
            </div>
            <div className="flex flex-col">
              <span className="pb-3 text-sm font-medium text-muted-foreground md:text-start">
                WhatsApp Number
              </span>
              <a href={`whatsapp://send?phone=${APP_PHONE}`} className="text-xl font-semibold text-primary md:text-start">
                {APP_PHONE}
              </a>
            </div>
          </div>
        </div>
      </Card>
    </Section>;
};