/* eslint-disable @typescript-eslint/no-empty-function */
'use client';

import { useEffect, useState } from 'react';
import { Button } from '@/components';
import { cn } from '@/utils';
import { CookieIcon } from 'lucide-react';

/* eslint-disable @typescript-eslint/no-empty-function */

export const CookieConsent = ({
  onAcceptCallback = () => {},
  onDeclineCallback = () => {}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const accept = () => {
    setIsOpen(false);
    document.cookie = 'cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    setTimeout(() => {
      setHide(true);
    }, 700);
    onAcceptCallback();
  };
  const decline = () => {
    setIsOpen(false);
    setTimeout(() => {
      setHide(true);
    }, 700);
    onDeclineCallback();
  };
  useEffect(() => {
    try {
      setIsOpen(true);
      if (document.cookie.includes('cookieConsent=true')) {
        setIsOpen(false);
        setTimeout(() => {
          setHide(true);
        }, 700);
      }
    } catch (e) {}
  }, []);
  return <div className={cn('fixed bottom-0 left-0 right-0 z-[200] w-full duration-700 sm:bottom-4 sm:left-4 sm:max-w-md', !isOpen ? 'translate-y-8 opacity-0 transition-[opacity,transform]' : 'translate-y-0 opacity-100 transition-[opacity,transform]', hide && 'hidden')} data-sentry-component="CookieConsent" data-sentry-source-file="cookie-consent.tsx">
      <div className="m-3 rounded-lg border border-border bg-background dark:bg-card">
        <div className="flex items-center justify-between p-3">
          <span className="block text-lg font-medium">We use cookies</span>
          <CookieIcon className="h-[1.2rem] w-[1.2rem]" data-sentry-element="CookieIcon" data-sentry-source-file="cookie-consent.tsx" />
        </div>
        <div className="-mt-2 p-3">
          <p className="text-left text-sm text-muted-foreground">
            We use cookies to ensure you get the best experience on our website.
            For more information on how we use cookies, please see our cookie
            policy.
          </p>
        </div>
        <div className="mt-2 flex items-center gap-2 border-t p-3">
          <Button onClick={accept} className="h-9 w-full rounded-full" data-sentry-element="Button" data-sentry-source-file="cookie-consent.tsx">
            accept
          </Button>
          <Button onClick={decline} className="h-9 w-full rounded-full" variant="outline" data-sentry-element="Button" data-sentry-source-file="cookie-consent.tsx">
            decline
          </Button>
        </div>
      </div>
    </div>;
};