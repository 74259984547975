import Link from 'next/link';
import { Button, Container } from '@/components';
import { PRODUCT_PLATFORMS } from '@/constants';
import { cn } from '@/utils';
import { TProductSlider } from './product-slider.types';
export const ProductSlider = ({
  slug
}: TProductSlider) => {
  const isSelected = (slugParam: string) => slug?.includes(slugParam);
  return <Container data-sentry-element="Container" data-sentry-component="ProductSlider" data-sentry-source-file="product-slider.tsx">
      <nav className="scroll no-scrollbar mt-4 mb-4 flex w-full gap-4 overflow-x-scroll py-1 md:mb-10">
        {PRODUCT_PLATFORMS?.map(product => <Link className="shrink-0" href={`/${encodeURIComponent(product.slug)}`} key={product.slug}>
            <Button variant={isSelected(product.uuid) ? 'default' : 'outline'} className={cn('px-4 py-3', {
          'bg-primary': isSelected(product.uuid)
        })}>
              <span className="text-sm font-bold lg:text-base">
                {product.name}
              </span>
            </Button>
          </Link>)}
      </nav>
    </Container>;
};