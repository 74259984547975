import { env } from '@/configs'
import { PRODUCT_QUALITY_KEYS } from '@/constants'
import { ProductProviders } from '@prisma/client'

type EnvType = {
  [key: string]: string
}

interface TAddPayload {
  link: string
  quality: PRODUCT_QUALITY_KEYS
  quantity: number
}

interface TAddResponse {
  process: string
  provider: string
}

interface FetchResponse {
  ok: boolean
  json: () => Promise<{
    order: string
  }>
}

export class ProcessOrder {
  private providers: ProductProviders[]

  constructor(providers: ProductProviders[]) {
    this.providers = providers
  }

  async add(data: TAddPayload): Promise<TAddResponse | undefined> {
    for (const provider of this.providers) {
      if (provider.active) {
        try {
          const params = new URLSearchParams({
            action: 'add',
            key: `${(env as EnvType)[`${provider.id}_SECRET`]}`,
            link: data.link,
            quantity: data.quantity.toString(),
            service:
              provider.serviceIdentifiers[
                data.quality || PRODUCT_QUALITY_KEYS.STANDARD
              ],
          })

          const response: FetchResponse = await fetch(
            `${(env as EnvType)[`${provider.id}_API`]}/?${params.toString()}}`,
            {
              method: 'POST',
              body: JSON.stringify(data),
            }
          )

          const json = await response.json()
          if (response.ok) {
            return { process: json.order, provider: provider.id }
          }
        } catch (error) {
          console.error(`Error fetching from ${provider.id}:`, error)
        }
      }
    }
  }
  orderStatus() {
    return 'orderStatus'
  }
}
