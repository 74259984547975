import { Badge, Container } from '@/components';
import { cn } from '@/utils';
import { TSection } from './section.types';
export const Section = ({
  children,
  className,
  title,
  label,
  description,
  layout = 'center',
  as: TitleTag = 'h2'
}: TSection) => {
  const alignmentClasses = {
    left: 'items-start text-left',
    center: 'items-center text-center',
    right: 'items-end text-right'
  };
  const titleClasses = {
    h1: 'text-3xl sm:text-4xl lg:text-5xl',
    h2: 'text-2xl sm:text-3xl lg:text-4xl',
    h3: 'text-xl sm:text-2xl lg:text-3xl',
    h4: 'text-lg sm:text-xl lg:text-2xl',
    h5: 'text-base sm:text-lg lg:text-xl',
    h6: 'text-sm sm:text-base lg:text-lg'
  };
  return <Container className="mt-12" data-sentry-element="Container" data-sentry-component="Section" data-sentry-source-file="section.tsx">
      <div className={cn('flex flex-col gap-4', alignmentClasses[layout])}>
        <div className={cn('flex flex-col gap-2', alignmentClasses[layout])}>
          {label && <Badge className="rounded-sm font-bold" variant="outline">
              {label}
            </Badge>}
          {title && <TitleTag className={cn('max-w-xl font-extrabold', titleClasses[TitleTag])}>
              {title}
            </TitleTag>}
        </div>
        {description && <p className={cn('lg:text-md max-w-xl text-muted-foreground sm:text-base', alignmentClasses[layout])}>
            {description}
          </p>}
      </div>

      <section className={cn('rounded-3xl py-6 lg:py-12', className)}>
        {children}
      </section>
    </Container>;
};