import Image from 'next/image';
import { Section } from '@/components';
import { getAssetPath } from '@/utils';
import { PLATFORMS } from './platform-block.consts';
export const PlatformBlock = () => {
  return <Section className="relative" title="Services We Offer" label="Our Services" as="h2" data-sentry-element="Section" data-sentry-component="PlatformBlock" data-sentry-source-file="platform-block.tsx">
      <div className="no-scrollbar flex w-full justify-between gap-6 overflow-x-scroll">
        {PLATFORMS.map(({
        title,
        logo,
        id
      }) => <div key={id} className="flex shrink-0 items-center justify-center overflow-hidden rounded-3xl bg-gray-50 p-4">
            <Image src={getAssetPath(`logo/circle/${logo}`)} alt={`${title} Logo`} width={80} height={80} className="grayscale transition-all duration-300 hover:grayscale-0" />
          </div>)}
      </div>
    </Section>;
};