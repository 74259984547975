'use client';

import { Badge, Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '@/components';
import { PRODUCT_QUALITY_LABELS, TOAST_LINK_COPIED_TO_CLIPBOARD } from '@/constants';
import { useCopyToClipboard, useToast } from '@/hooks';
import { cn } from '@/utils';
import { MoreHorizontalIcon } from 'lucide-react';
import { Input } from '../../ui/input';
export type CartItemProps = {
  name: string;
  link: string;
  id?: string;
  quantity: string | number;
  quality: string;
  price: string;
  actions?: boolean;
  onRemove?: ($id: string) => void;
  className?: string;
};
export const CartItem = ({
  name,
  link,
  id,
  quantity,
  quality,
  price,
  actions = true,
  className,
  onRemove
}: CartItemProps) => {
  const [, copy] = useCopyToClipboard();
  const {
    toast
  } = useToast();
  const handleCopy = (link: string) => {
    copy(link);
    toast({
      ...TOAST_LINK_COPIED_TO_CLIPBOARD,
      description: link
    });
  };
  return <div className={cn('relative flex w-full flex-1 flex-col justify-between gap-1 space-y-1.5 rounded-xl border border-gray-100 p-4 text-left lg:gap-2', className)} data-sentry-component="CartItem" data-sentry-source-file="cart-item.tsx">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="block text-sm font-semibold text-gray-900">
            {name}
          </span>
          <Badge className="sm:text-md flex h-5 items-center justify-center rounded-sm text-xs" data-sentry-element="Badge" data-sentry-source-file="cart-item.tsx">
            {PRODUCT_QUALITY_LABELS[quality as keyof typeof PRODUCT_QUALITY_LABELS]}
          </Badge>
        </div>

        {actions && <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button type="button" size="icon" variant="outline" className="h-7 w-7 rounded-sm text-gray-600">
                <MoreHorizontalIcon size={16} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => handleCopy(link)}>
                Copy Link
              </DropdownMenuItem>
              {<DropdownMenuItem onClick={() => {
            if (id) {
              onRemove && onRemove(id);
            }
          }}>
                  Delete
                </DropdownMenuItem>}
            </DropdownMenuContent>
          </DropdownMenu>}
      </div>
      <Input type="text" value={link} readOnly disabled className="h-8 w-full border-none bg-gray-50 px-2 text-xs italic text-foreground" data-sentry-element="Input" data-sentry-source-file="cart-item.tsx" />
      {/* <span className="text-xs italic text-muted-foreground underline">
        {link}
       </span> */}
      <div className="flex justify-between gap-2">
        <div className="flex gap-2">
          <Badge variant="secondary" className="sm:text-md flex h-6 items-center justify-center rounded-sm text-xs" data-sentry-element="Badge" data-sentry-source-file="cart-item.tsx">
            {quantity}
          </Badge>
        </div>
        <div className="flex items-center gap-2">
          <Badge variant="secondary" className="sm:text-md flex h-6 items-center justify-center rounded-sm text-xs" data-sentry-element="Badge" data-sentry-source-file="cart-item.tsx">
            {price}
          </Badge>
        </div>
      </div>
    </div>;
};