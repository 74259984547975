'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { Badge, Button, Card, Drawer, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, Separator, Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from '@/components';
import { useMediaQuery } from '@/hooks';
import { useCartStore } from '@/providers';
import { CreditCardIcon, PackagePlusIcon } from 'lucide-react';
import { CartButton } from './cart-button';
import { CartList } from './cart-list';
import { CartTitle } from './cart-title';
export const CartRenderer = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const router = useRouter();
  const path = usePathname();
  const queries = useSearchParams();
  const {
    cartSize,
    cartIsEmpty,
    cart,
    $open,
    toggleCart,
    $products,
    clearCart
  } = useCartStore(state => state);
  const disabled = ['/checkout', '/cart'].includes(path);
  if (queries.get('status') === 'success') {
    clearCart();
  }
  const handleCheckout = () => {
    if (cartIsEmpty() || disabled) return;
    toggleCart(false);
    router.push('/checkout');
  };
  if (isMobile) {
    return <>
        <CartButton length={cartSize()} disabled={disabled || $products.length === 0} onClick={() => toggleCart(!$open)} />
        <Drawer onClose={() => toggleCart(false)} open={$open} onOpenChange={e => toggleCart(e)}>
          <DrawerContent>
            <div className="mx-auto w-full">
              <DrawerHeader>
                <DrawerTitle className="flex items-center justify-between">
                  <span className="text-sm">{`${cartSize()} items `}</span>
                  <CartTitle />
                  <span className="text-sm">{` ${cart().price}`}</span>
                </DrawerTitle>
              </DrawerHeader>
              <div className="no-scrollbar h-[60vh] space-y-4 overflow-y-scroll px-4">
                <CartList cart={cart().items} />
              </div>
              <DrawerFooter>
                <Button className="w-full  px-4 font-bold" size="lg" onClick={handleCheckout}>
                  CHECKOUT
                </Button>
              </DrawerFooter>
            </div>
          </DrawerContent>
        </Drawer>
      </>;
  } else {
    return <>
        <CartButton length={cartSize()} disabled={disabled} onClick={() => toggleCart(!$open)} />
        <Sheet open={$open} onOpenChange={e => toggleCart(e)}>
          <SheetContent className="flex max-h-screen flex-col gap-4">
            <SheetHeader className="flex items-start">
              <SheetTitle>
                <CartTitle />
              </SheetTitle>
              <SheetDescription>
                Review your items before checkout.
              </SheetDescription>
            </SheetHeader>
            {cartIsEmpty() ? <div className="mt-10 flex h-full flex-col items-center justify-center">
                <PackagePlusIcon className="h-auto w-full text-gray-100" />
                <span className="text-2xl font-light text-primary">
                  Your cart is empty
                </span>
                <span className="text-center text-sm font-light text-gray-500">
                  Start adding items to your cart by clicking the add to cart
                  button
                </span>
              </div> : <div className="h-4/7 no-scrollbar space-y-2 overflow-y-scroll">
                <CartList cart={cart().items} />
              </div>}
            <SheetFooter>
              {!cartIsEmpty() && <Card className="w-full space-y-3.5 p-4">
                  <div className="text-md flex flex-row justify-between">
                    <span className="font-semibold">Total</span>
                    <span className="font-bold">{cart().price}</span>
                  </div>
                  <Separator />
                  <div className="text-sm text-muted-foreground">
                    You have{' '}
                    <Badge className="mx-1 rounded-sm text-xs">
                      {cartSize()}
                    </Badge>{' '}
                    items in your cart.
                  </div>
                  <Button className="w-full gap-2 px-0 font-bold" size="lg" onClick={handleCheckout}>
                    CHECKOUT
                    <CreditCardIcon size={16} />
                  </Button>
                </Card>}
            </SheetFooter>
          </SheetContent>
        </Sheet>
      </>;
  }
};