import Image from 'next/image';
import { Card, Section } from '@/components';
import { APP_FEATURES, APP_NAME } from '@/constants';
import { cn, getAssetPath } from '@/utils';
export const WhyChooseUs = () => {
  return <Section title="Why Choose Us?" label={APP_NAME} data-sentry-element="Section" data-sentry-component="WhyChooseUs" data-sentry-source-file="why-choose-us.tsx">
      <Card className={cn('flex h-full flex-col items-center gap-6 border-0 shadow-none lg:flex-row lg:gap-16')} data-sentry-element="Card" data-sentry-source-file="why-choose-us.tsx">
        <div className="shrink-0">
          <Image src={getAssetPath('why-choose-us.jpg')} alt="Why Choose Us?" width={500} height={500} className="rounded-3xl" data-sentry-element="Image" data-sentry-source-file="why-choose-us.tsx" />
        </div>

        <div className="flex flex-col gap-2 text-left">
          <div className="flex h-fit flex-col items-stretch justify-center gap-4 lg:grid lg:grid-cols-2">
            {APP_FEATURES.map(({
            Icon,
            description,
            title,
            id
          }) => <div key={id} className="col-span-1 flex h-auto w-full flex-row items-start gap-4 rounded-2xl bg-gray-50 p-6 lg:flex-col lg:items-center">
                <div className="flex h-16 w-16 shrink-0 items-center justify-center rounded-2xl bg-primary">
                  <Icon className="text-white" size={28} />
                </div>
                <div className="flex flex-col gap-2 text-left lg:text-center">
                  <h3 className="text-lg font-bold">{title}</h3>
                  <p className="text-muted-foreground">{description}</p>
                </div>
              </div>)}
          </div>
        </div>
      </Card>
    </Section>;
};