import { PRODUCT_QUALITY_KEYS } from '@/constants'
import { TCart, TCartItem, TLocalCart, TLocalCartItem, TProduct } from '@/types'
import { capitalizeFirstLetter, formatCurrency, formatNumber } from '@/utils'
import { Product } from '@prisma/client'
import { nanoid } from 'nanoid'
import { createStore } from 'zustand'
import { persist } from 'zustand/middleware'

export type TCartState = {
  $cart: TLocalCart
  $products: TProduct[]
  $open: boolean
}

export type TCartActions = {
  addToCart: (item: TLocalCartItem) => boolean
  removeFromCart: ($id: string) => boolean
  clearCart: () => void
  cartIsEmpty: () => boolean
  cartSize: () => number
  cart: () => TCart
  toggleCart: (val: boolean) => void
}

export type TCartStore = TCartState & TCartActions

export const defaultInitState: TCartState = {
  $cart: [],
  $products: [],
  $open: false,
}

export const createCartStore = (initState: TCartState = defaultInitState) => {
  return createStore<TCartStore>()(
    persist(
      (set, get) => ({
        ...initState,
        addToCart: (item: TLocalCartItem) => {
          const existingItem = get().$cart.find((cartItem) => {
            if (cartItem.link === item.link) {
              if (cartItem.id === item.id) {
                return true
              }
            }
          })

          const isValidProduct = get().$products.find(
            (product) => product.id === item.id
          )

          if (existingItem || !item || !isValidProduct) {
            return false
          }

          set((state: TCartStore) => ({
            $cart: [{ ...item, $id: nanoid(8) }, ...state.$cart],
          }))
          return true
        },

        removeFromCart: ($id: string) => {
          const existingItem = get().$cart.find((item) => item.$id === $id)

          if (!existingItem) {
            return false
          }

          set((state: TCartStore) => ({
            $cart: state.$cart.filter((item) => item.$id !== $id) as TLocalCart,
          }))

          return true
        },
        cartIsEmpty: () => get().$cart.length === 0,
        cartSize: () => get().$cart.length,
        toggleCart: (val: boolean) => {
          set({
            $open: val,
          })
        },
        clearCart: () => {
          if (get().$cart.length === 0) return

          set({
            $cart: [],
          })
        },
        cart: (): TCart => {
          let totalPrice = 0

          const cartMap = get()
            .$cart.map((item) => {
              const product = get().$products.find(
                (product: Product) => product.id === item.id
              )
              if (!product) {
                set({
                  $cart: get().$cart.filter(
                    (cartItem) => cartItem.id !== item.id
                  ),
                })
                return false
              }

              const price =
                item.quantity *
                product.price[item.quality as PRODUCT_QUALITY_KEYS]

              totalPrice += price

              return {
                id: item.id,
                $id: item.$id,
                name: `${capitalizeFirstLetter(product.platform)} ${
                  product.name
                }`,
                price: formatCurrency(price),
                quantity: formatNumber(item.quantity),
                link: item.link,
                quality: item.quality,
              }
            })
            .filter(Boolean) as TCartItem[]

          return {
            items: cartMap,
            price: formatCurrency(totalPrice),
          }
        },
      }),
      {
        name: 'cart',
      }
    )
  )
}
