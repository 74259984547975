import Link from 'next/link';
import { getAssetPath } from '@/utils';
import { ArrowLeftIcon } from 'lucide-react';
import { Button } from '../../ui/button';
import { TAuthLayout } from './auth-layout.types';
export const AuthLayout = ({
  children,
  title,
  description,
  video
}: TAuthLayout) => {
  return <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]" data-sentry-component="AuthLayout" data-sentry-source-file="auth-layout.tsx">
      <div className="relative flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">{title}</h1>
            <p className="text-balance text-muted-foreground">{description}</p>
          </div>
          {children}
        </div>
        <Button variant="secondary" asChild className="absolute left-4 top-4 flex animate-pulse gap-2" data-sentry-element="Button" data-sentry-source-file="auth-layout.tsx">
          <Link href="/" data-sentry-element="Link" data-sentry-source-file="auth-layout.tsx">
            <ArrowLeftIcon data-sentry-element="ArrowLeftIcon" data-sentry-source-file="auth-layout.tsx" />
            <span>HOME</span>
          </Link>
        </Button>
      </div>
      <div className="relative hidden h-screen bg-muted lg:block">
        <video src={getAssetPath(video)} autoPlay muted loop playsInline className="event h-full w-full object-cover" />
      </div>
    </div>;
};