import { TMailArgs } from '@/types'
import { $fetch } from '@/utils'

export const postMail = async (args: TMailArgs): Promise<any> => {
  const response = await $fetch('/mail', {
    method: 'POST',
    body: JSON.stringify(args),
  })

  return response.data
}
