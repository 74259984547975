export const PLATFORMS = [
  {
    id: 1,
    title: 'Tiktok',
    logo: 'tiktok.svg',
  },
  {
    id: 2,
    title: 'Instagram',
    logo: 'instagram.svg',
  },
  {
    id: 3,
    title: 'Facebook',
    logo: 'facebook.svg',
  },
  {
    id: 4,
    title: 'Twitter',
    logo: 'twitter.svg',
  },
  {
    id: 5,
    title: 'Threads',
    logo: 'threads.svg',
  },
  {
    id: 6,
    title: 'Snapchat',
    logo: 'snapchat.svg',
  },
  {
    id: 7,
    title: 'Youtube',
    logo: 'youtube.svg',
  },

  {
    id: 8,
    title: 'Telegram',
    logo: 'telegram.svg',
  },
  {
    id: 9,
    title: 'Spotify',
    logo: 'spotify.svg',
  },
]
